<template>
  <div class="orderDetailsDemo  page-info-content">
    <!-- <h2 class="orderDetailsTitle">过户详情</h2> -->
    <!-- 这个是流程模块 -->
    <div class="flowPageBox">
      <h3 class="title">
        过户流程
        <span>过户单号：{{ detailsObj.detailsObj.goodsTransferNo }}</span>
      </h3>
      <!-- 流程图 -->
      <div v-if="detailsObj.detailsObj.orderSource === '00'" class="flowBox">
        <div v-for="(item, index) in flowArr" :key="index" class="singleFlow">
          <p>{{ index + 1 }}</p>
          <span>{{ item.name }}</span><i />
          <b>{{ item.title }}</b>
        </div>
      </div>
    </div>
    <!-- 商品信息 -->
    <el-descriptions title="商品信息" :column="3">
      <el-descriptions-item label="下单时间">
        {{
          detailsObj.detailsObj.createTime
        }}
      </el-descriptions-item>
      <el-descriptions-item label="订单编号">
        {{
          detailsObj.detailsObj.orderNo
        }}
      </el-descriptions-item>
      <!-- 买家显示卖家的   卖家显示买家的 -->
      <el-descriptions-item label="商家">
        {{
          detailsObj.detailsObj.cmpName
        }}
      </el-descriptions-item>
      <el-descriptions-item label="商家联系人">
        {{
          `${detailsObj.detailsObj.listingPerson} ${detailsObj.detailsObj.personPhone}`
        }}
      </el-descriptions-item>
      <el-descriptions-item label="买家">
        {{
          detailsObj.detailsObj.buyerCmpName
        }}
      </el-descriptions-item>
      <el-descriptions-item label="买家联系人">
        {{
          `${detailsObj.detailsObj.receivingPerson} ${detailsObj.detailsObj.receivingPhone}`
        }}
      </el-descriptions-item>
      >
      <el-descriptions-item
        v-if="detailsObj.detailsObj.orderSource !== '02'"
        label="自提地址"
        :span="3"
      >
        {{ detailsObj.detailsObj.warehouseAddress }}
        {{ detailsObj.detailsObj.warehousePerson }}
        {{ detailsObj.detailsObj.warehousePhone }}
      </el-descriptions-item>
      <!-- 后台录入的话就显示货主 -->
      <el-descriptions-item
        v-if="detailsObj.detailsObj.orderSource === '01'||detailsObj.detailsObj.orderSource === '02'"
        label="原货主"
      >
        {{ detailsObj.detailsObj.cmpName }}
      </el-descriptions-item>
      <el-descriptions-item
        v-if="detailsObj.detailsObj.orderSource === '01'||detailsObj.detailsObj.orderSource === '02'"
        label=" 新货主"
        :span="detailsObj.detailsObj.orderSource === '01'||detailsObj.detailsObj.orderSource === '02' ? 1 : 3"
      >
        {{ detailsObj.detailsObj.buyerCmpName }}
      </el-descriptions-item>
      <el-descriptions-item
        v-if="detailsObj.detailsObj.orderSource === '01'||detailsObj.detailsObj.orderSource === '02'"
        label="商品共计"
      >
        {{ detailsObj.detailsObj.orderWeight }}{{ unit }}
      </el-descriptions-item>
      <el-descriptions-item
        v-if="detailsObj.detailsObj.orderSource === '01'||detailsObj.detailsObj.orderSource === '02'"
        label="订单总额"
      >
        <span
          class="lookOrder"
        >￥{{ detailsObj.detailsObj.orderAmount }}</span>
      </el-descriptions-item>
      <!-- buyerCmpName -->
    </el-descriptions>
    <div class="table-box">
      <Table
        :item-data="goodsItemData"
        :list-data="productLists"
        :loading="loading"
      />
      <!-- 点击更多按钮当显示完成之后  就不要显示了 -->
      <p
        v-if="
          detailsObj.detailsObj.productLists && detailsObj.detailsObj.productLists.length && detailsObj.detailsObj.productLists.length > 5
        "
        class="more"
        @click="getMoreData"
      >
        展开全部{{
          detailsObj.detailsObj.productLists.length || 0
        }}条商品<i class="el-icon-arrow-down" />
      </p>
    </div>
    <!-- 过户信息开始 前台下单-->
    <el-descriptions
      v-if="detailsObj.detailsObj.orderSource === '00'"
      title="过户信息"
      :column="3"
    >
      <el-descriptions-item label="" label-class-name="labelHidden" content-class-name="table-descriptions" :span="3">
        <Table
          :item-data="itemDataPicking"
          :list-data="detailsObj.listData"
          :loading="loading"
        />
      </el-descriptions-item>
      <el-descriptions-item label="支付方式">
        {{
          detailsObj.detailsObj.payStyle === "00" ? "全款支付" : "保证金支付"
        }}
      </el-descriptions-item>
      <el-descriptions-item
        label="订单总额"
      >
        ￥{{ detailsObj.detailsObj.orderAmount }}
      </el-descriptions-item>
      <el-descriptions-item
        label="本次申请过户货款"
      >
        ￥{{ detailsObj.detailsObj.payTotalAmount || 0 }}
      </el-descriptions-item>
      <el-descriptions-item
        v-if="detailsObj.detailsObj.payStyle !== '00'"
        label="已付保证金"
      >
        ￥{{
          detailsObj.detailsObj.bondAmount || 0
        }}
      </el-descriptions-item>
      <el-descriptions-item
        v-if="detailsObj.detailsObj.payStyle !== '00'"
        label="本次已抵扣保证金"
      >
        ￥{{ detailsObj.detailsObj.payBondAmount || 0 }}
      </el-descriptions-item>
      <el-descriptions-item
        v-if="detailsObj.detailsObj.payStyle !== '00'"
        label="累计已抵扣保证金"
      >
        ￥{{ detailsObj.detailsObj.cumulativeBondAmount || 0 }}
      </el-descriptions-item>
      <el-descriptions-item
        v-if="detailsObj.detailsObj.payStyle !== '00'"
        label="本次过户支付"
      >
        ￥{{ detailsObj.detailsObj.payCopeAmount || 0 }}
      </el-descriptions-item>
    </el-descriptions>
    <!-- 后台录入 -->
    <el-descriptions
      v-if="detailsObj.detailsObj.orderSource === '01'||detailsObj.detailsObj.orderSource === '02'"
      title="过户信息"
      :column="3"
    >
      <el-descriptions-item label="过户单状态">
        {{
          (
            $store.getters
              .getDictionaryItem("transfer_bill_status")
              .find(
                (item) => item.dictId === detailsObj.detailsObj.transferStatus
              ) || {}
          ).dictName || ""
        }}
      </el-descriptions-item>
      <el-descriptions-item
        label="过户数量/单位"
      >
        {{ detailsObj.detailsObj.orderWeight }}{{ unit }}
      </el-descriptions-item>
      <el-descriptions-item label="过户时间">
        {{
          detailsObj.detailsObj.transferInventoryTime
        }}
      </el-descriptions-item>
    </el-descriptions>
    <!-- 过户信息结束 -->
    <el-descriptions
      v-if="detailsObj.detailsObj.orderSource === '00'"
      title="过户审批信息"
      :column="3"
    >
      <el-descriptions-item label="过户单状态">
        {{
          (
            $store.getters
              .getDictionaryItem("transfer_bill_status")
              .find(
                (item) => item.dictId === detailsObj.detailsObj.transferStatus
              ) || {}
          ).dictName || ""
        }}
      </el-descriptions-item>
      <el-descriptions-item label="过户申请人">
        {{
          detailsObj.detailsObj.transferApplyPerson
        }}
      </el-descriptions-item>
      <el-descriptions-item label="过户申请时间">
        {{
          detailsObj.detailsObj.transferApplyTime
        }}
      </el-descriptions-item>
      <el-descriptions-item label="审核结果">
        <span v-if="detailsObj.detailsObj.reviewResult === '01'">审核通过</span>
        <span v-else-if="detailsObj.detailsObj.reviewResult === '00'">审核未通过</span>
      </el-descriptions-item>
      <el-descriptions-item label="审核人">
        {{
          detailsObj.detailsObj.reviewPerson
        }}
      </el-descriptions-item>
      <el-descriptions-item label="审核时间">
        {{
          detailsObj.detailsObj.reviewTime
        }}
      </el-descriptions-item>
      <el-descriptions-item label="审核备注">
        {{
          detailsObj.detailsObj.reviewRemark
        }}
      </el-descriptions-item>
    </el-descriptions>
    <el-descriptions
      v-if="
        detailsObj.detailsObj.transferStatus === '03' &&
          detailsObj.detailsObj.orderSource === '00'

      "
      title="过户记录"
      :column="3"
    >
      <el-descriptions-item label="仓库过户单号">
        {{
          detailsObj.detailsObj.transferInventoryNo
        }}
      </el-descriptions-item>
      <el-descriptions-item label="过户时间">
        {{
          detailsObj.detailsObj.transferInventoryTime
        }}
      </el-descriptions-item>
    </el-descriptions>
  </div>
</template>

<script>
import Table from '@/components/Table.vue'
import { tradeOrderTransferBillDetail } from '@/api/shoppingMall'
import { subPrecision } from '@/utils/util'

export default {
  components: { Table },
  data() {
    return {
      total: 0,
      loading: false,
      productLists: [],
      temporaryCmpRole: [],
      unit: '吨',
      flowArr: [
        { name: '发起过户申请', title: '' },
        { name: '待商家审核', title: '' },
        { name: '完成过户', title: '' }
      ],
      formData: {},
      detailsObj: {
        detailsObj: {},
        listData: [],
        goodsListData: []
      },
      // userCmpCode: sessionStorage.userCmpRole
      // ? sessionStorage.userCmpRole
      // : "02",
      itemDataPicking: [
        { label: '订单数量/单位', prop: 'orderWeight', width: 180, unit: '吨' },
        {
          label: '累计申请数量/单位',
          prop: 'cumulativeAppliedTransferWeight',
          width: 180,
          unit: '吨'
        },
        {
          label: '剩余可申请数量/单位',
          prop: 'surplusApplyWeight',
          width: 180,
          unit: '吨'
        },
        {
          label: '本次申请量/单位',
          prop: 'transferWeight',
          width: 180,
          unit: '吨'
        }
      ],
      goodsItemData: [],
      goodsItemConfig: {
        '00': [
          { label: '品名/材质/规格', prop: 'goodsName', width: 180 },
          { label: '钢厂/厂家', prop: 'manufactor', width: 180 },
          { label: '仓库', prop: 'warehouseName', width: 180 },
          {
            label: '含税单价（元）',
            prop: 'unitPriceIncludingTax',
            type: 'money',
            width: 180
          },
          { label: '订单数量/单位', prop: 'orderWeight', width: 180, unit: '吨' }
        ],
        '01': [
          { label: '品名', prop: 'goodsTwoCategoryName', width: 180 },
          { label: '材质', prop: 'goodsMaterialName', width: 180 },
          { label: '规格', prop: 'goodsSpecName', width: 180 },
          { label: '产地/厂家', prop: 'manufactor', width: 180 },
          { label: '数量/单位', prop: 'goodsWeight', width: 180, unit: '吨' },
          { label: '含税单价（元）', prop: 'unitPriceIncludingTax', width: 180 },
          { label: '小计（元）', prop: 'goodsAmount', width: 180 }
        ],
        '02': [
          { label: '品名', prop: 'goodsTwoCategoryName', width: 180 },
          { label: '材质', prop: 'goodsMaterialName', width: 180 },
          { label: '规格', prop: 'goodsSpecName', width: 180 },
          { label: '产地/厂家', prop: 'manufactor', width: 180 },
          { label: '数量/单位', prop: 'goodsWeight', width: 180, unit: '吨' },
          { label: '含税单价（元）', prop: 'unitPriceIncludingTax', width: 180 },
          { label: '小计（元）', prop: 'goodsAmount', width: 180 }
        ]
      }

    }
  },
  mounted() {
    this.getDetails()
  },
  methods: {
    // 获取更多信息
    getMoreData() {
      this.productLists = [...this.detailsObj.detailsObj.productLists]
    },
    getDetails() {
      if (this.$route.query.id) {
        tradeOrderTransferBillDetail(this.$route.query.id, (res) => {
          this.goodsItemData = this.goodsItemConfig[res.data.orderSource]
          if (res.data.productLists) {
            res.data.productLists.forEach((item, index) => {
              // 动态单位
              if (index === 0 && item.unitMeasurement) {
                this.unit = item.unitMeasurement
                this.goodsItemData.forEach(val => {
                  if (val.unit)val.unit = item.unitMeasurement
                })
                this.itemDataPicking.forEach(val => {
                  if (val.unit)val.unit = item.unitMeasurement
                })
              }
              item.orderWeight = res.data.orderWeight
              item.orderFreight = res.data.orderFreight
              item.bondRate = res.data.bondRate
                ? res.data.bondRate + '%'
                : '100%'
            })
          }
          this.detailsObj.detailsObj = { ...res.data }

          switch (this.detailsObj.detailsObj.transferStatus) {
            case '04':
              this.flowArr[1].name = '商家审核不通过'
              break
            case '03':
              this.flowArr[1].name = '商家审核通过'
              break
            default:
              break
          }
          // 计算剩余可申请
          this.detailsObj.detailsObj.maximumNumberApplications = subPrecision(Number(this.detailsObj.detailsObj.orderWeight), Number(this.detailsObj.detailsObj.cumulativeAppliedTransferWeight)).toFixed(3)
          // 后端返回的是一个对象   所以要转为数组
          this.detailsObj.listData.push({
            transferWeight: this.detailsObj.detailsObj.transferWeight,
            orderWeight: this.detailsObj.detailsObj.orderWeight,
            surplusApplyWeight: this.detailsObj.detailsObj.remainingWeight,
            cumulativeAppliedTransferWeight: subPrecision(Number(this.detailsObj.detailsObj.orderWeight), Number(this.detailsObj.detailsObj.remainingWeight)).toFixed(3)
          })
          if (res.data && res.data.productLists) { this.productLists = res.data.productLists.slice(0, 5) }
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
/deep/ .labelHidden {
  display: none;
}
/deep/ .table-descriptions {
  width: 100%;
}
.orderDetailsDemo {
  background-color: #fff;
  .table-box{
    padding-bottom: 24px;
  }
  .more {
    text-align: center;
    padding-top: 12px;
    color: #666;
    cursor: pointer;
    .el-icon {
      margin-left: 8px;
      font-size: 14px;
      font-weight: 500;
    }
  }
  .orderDetailsTitle {
    color: #000;
    font-size: 18px;
    padding-bottom: 16px;
  }
  .flowPageBox {
  background-color: #fff;
  padding: 24px;
  .flowBox {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    .singleFlow {
      position: relative;
      flex: 1;
      padding-right: 16px;
      display: flex;
      align-items: center;
      color:#0089FF;
      padding-bottom: 16px;
      b {
        position: absolute;
        top: 28px;
        left: 40px;
        font-size: 14px;
        color: #666;
        font-weight: 500;
      }
      span {
        padding: 0 16px;
      }
      i {
        display: inline-block;
        flex: 1;
        height: 2px;
        background-color:#0089FF;
      }
      p {
        display: inline-block;
        height: 24px;
        width: 24px;
        line-height: 24px;
        text-align: center;
        background-color:#0089FF;
        color: #fff;
        border-radius: 50%;
        font-size: 12px;
        font-style: normal;
      }
    }
    .singleFlow:last-of-type {
      // padding-right: 0;
      flex: none;
    }
  }
  .title {
    color: #333;
    font-size: 16px;
    padding-bottom: 32px;
    transform: translateX(-24px);
    span {
      margin-left: 12px;
      color: #666;
      font-size: 14px;
      font-weight: 500;
    }
  }
}
}
</style>
